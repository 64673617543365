import {
	GET_WEEKS,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class Weeks {
	constructor() {
		const axios = new Req();
		this.data = axios;
		this.errors = axios.errors;
		this.loading = axios.loading;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getWeeks(option) {
		const { method, endpoint } = GET_WEEKS;
		if (typeof options !== 'undefined') {
			this.options.data = { approved: option };
		}
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Weeks;
