<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<b-button
					:disabled="loading"
					class="float-right"
					variant="success"
					@click="downloadReport">
					<i :class="!loading ? 'fas fa-file-excel' : 'fas fa-spinner fa-spin'" /> {{ translate('export') }}
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div class="col-12 col-md-4">
						<search
							v-model="filters.id"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role: distributor}"
							class="mb-3"
							q="label"
							return-key="id" />
					</div>
					<div class="col-12 col-md-4">
						<div class="form-group">
							<label
								for="status"
								class="label">{{ translate('status') }}</label>
							<select
								id="status"
								v-model="filters.status"
								name="status"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="status in statuses"
									:key="status"
									:value="status">
									{{ translate(status) }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-12 col-md-4">
						<div class="form-group">
							<label
								for="week"
								class="label">
								{{ translate('week') }}
							</label>
							<select
								v-model="dateRange"
								name="week"
								class="form-control text-capitalize"
								value="">
								<option value="all">
									{{ translate('all') }}
								</option>
								<option :value="custom">
									{{ translate('custom') }}
								</option>
								<option
									v-for="week in weeks"
									:key="week.attributes.number"
									:value="week.attributes.number">
									{{ translate('week_number', { number: week.attributes.number }) }}
								</option>
							</select>
						</div>
					</div>
				</div>
				<div class="row">
					<div
						v-if="dateRange === custom"
						class="col-12 col-md-4">
						<div
							class="form-group">
							<label
								for="start_week"
								class="label">
								{{ translate('initial_week') }}
							</label>
							<select
								id="startWeek"
								v-model="filters.startWeek"
								name="startWeek"
								class="form-control text-capitalize"
								value="">
								<option
									v-for="week in weeks"
									:key="week.attributes.number"
									:value="week.attributes.number">
									{{ translate('week_number', { number: week.attributes.number }) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange === custom"
						class="col-12 col-md-4">
						<div
							class="form-group">
							<label
								for="end_week"
								class="label">
								{{ translate('end_week') }}
							</label>
							<select
								id="endWeek"
								v-model="filters.endWeek"
								name="endWeek"
								class="form-control text-capitalize"
								value="">
								<option
									v-for="week in weeks"
									:key="week.attributes.number"
									:value="week.attributes.number">
									{{ translate('week_number', { number: week.attributes.number }) }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<div class="row">
			<div class="col">
				<div
					v-if="!loading"
					class="table-responsive">
					<table class="table table-hover table-striped text-nowrap">
						<thead>
							<tr>
								<th class="border-top-0">
									#
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('u.id')">
									{{ translate('user_id') }}
									<sort field="u.id" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('username')">
									{{ translate('username') }}
									<sort field="username" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('full_name')">
									{{ translate('full_name') }}
									<sort field="full_name" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('email')">
									{{ translate('email') }}
									<sort field="email" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('u.status')">
									{{ translate('status') }}
									<sort field="u.status" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('highest_rank')">
									{{ translate('highest_rank') }}
									<sort field="highest_rank" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('w.number')">
									{{ translate('week') }}
									<sort field="w.number" />
								</th>
							</tr>
						</thead>
						<tbody v-if="hasData">
							<tr
								v-for="(item, index) in data"
								:key="index">
								<td class="align-middle">
									{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
								</td>
								<td
									class="align-middle">
									<span
										class="badge badge-primary pointer"
										@click="toDashboard({ distributorId: item.id.toString() })">
										{{ item.id }}
									</span>
								</td>
								<td class="align-middle">
									{{ item.attributes.username }}
								</td>
								<td class="align-middle">
									{{ item.attributes.name }}
								</td>
								<td class="align-middle">
									{{ item.attributes.email }}
								</td>
								<td class="align-middle">
									{{ translate(item.attributes.status) }}
								</td>
								<td class="align-middle d-flex align-items-center">
									{{ translate(item.attributes.highest_rank) }}
									<template v-if="item.attributes.is_same_rank && subRankIndicator(item.attributes, 'highest_sub_rank') !== null">
										<img
											:src="`${S3_PATH}/assets/ranks/${themeName}/${subRankIndicator(item.attributes, 'highest_sub_rank')}_indicator.png`"
											class="ml-1 mb-1"
											alt="elite">
									</template>
								</td>
								<td class="align-middle">
									{{ item.attributes.week_number }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>

<script>
import DataFilter from '@/components/DataFilter';
import { SEARCH_USERS } from '@/config/endpoint';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import SubRanks from '@/mixins/SubRanks';
import FiltersParams from '@/mixins/FiltersParams';
import { YMD_FORMAT, custom } from '@/settings/Dates';
import { PAGINATION } from '@/settings/RequestReply';
import { distributor } from '@/settings/Roles';
import { STATUSES } from '@/settings/Statuses';
import { Grids, Ranks } from '@/translations';
import Reports from '@/util/Report';
import Weeks from '@/util/Weeks';
import { S3_PATH } from '@/settings/Images';

export default {
	name: 'RisingStars',
	messages: [Grids, Ranks],
	components: { DataFilter },
	mixins: [FiltersParams, DashboardRedirect, SubRanks],
	data() {
		return {
			alerts: new this.$Alert(),
			reports: new Reports(),
			downloadRisingStars: new Reports(),
			weeksInfo: new Weeks(),
			endpoint: SEARCH_USERS.endpoint,
			statuses: STATUSES,
			distributor,
			themeName: process.env.VUE_APP_THEME,
			S3_PATH,
			custom,
			dateRange: '',
		};
	},
	computed: {
		loading() {
			try {
				return this.reports.data.loading;
			} catch (error) {
				return false;
			}
		},
		pagination() {
			return this.reports.data.pagination;
		},
		data() {
			try {
				return this.reports.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		weeks() {
			try {
				return this.weeksInfo.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			try {
				return !!this.data.length;
			} catch (error) {
				return false;
			}
		},
		loadedWeek() {
			try {
				return Number(this.reports.data.response.data.meta.week_number);
			} catch (error) {
				return 0;
			}
		},
	},
	watch: {
		loadedWeek(newVal) {
			if (typeof this.dateRange === 'undefined') {
				this.dateRange = newVal;
			}
		},
	},
	mounted() {
		this.weeksInfo.getWeeks();
		this.getRisingStars();
	},
	methods: {
		getRisingStars(params) {
			this.reports.getRisingStars(params).then(() => {
				const { query } = this.$route;
				this.filters.startWeek = this.reports.data.response.data.meta.week_number;
				this.filters.endWeek = this.reports.data.response.data.meta.week_number;
				if (query.startWeek && query.endWeek) {
					this.dateRange = custom;
					this.filters.startWeek = query.startWeek;
					this.filters.endWeek = query.endWeek;
				} else if (typeof query.dateRange !== 'undefined') {
					this.dateRange = query.dateRange;
				} else {
					this.dateRange = this.reports.data.response.data.meta.week_number;
				}
			});
		},
		getDataFiltered(page) {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };

			if (this.dateRange === this.loadedWeek) {
				options.dateRange = null;
			}
			if (this.dateRange !== custom) {
				options.startWeek = null;
				options.endWeek = null;
			}
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});
			this.$router.push({ name: this.$route.name, query: options }).catch(() => {});
			this.getRisingStars(options);
		},
		downloadReport() {
			this.alerts.loading(this.translate('loading'), this.translate('loading_text'), { allowOutsideClick: false, allowEscapeKey: false });

			this.downloadRisingStars.downloadRisingStars().then(() => {
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(this.downloadRisingStars.data.response.data);
				link.setAttribute('download', `${this.translate('rising_stars')} - ${this.$moment().format(YMD_FORMAT)}.xlsx`);
				this.$el.appendChild(link);
				link.click();
			}).catch(() => {
			}).finally(() => {
				this.alerts.close();
			});
		},
	},
};
</script>
